import React from 'react';

export default ({ color = 'rgb(154,195, 97)' }) => (
  <svg viewBox="0 0 100 100">
    <path d="M15 82.5h70c.8 0 1.5-.7 1.5-1.5V24c0-.8-.7-1.5-1.5-1.5h-8.5V19c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5v3.5h-5.3V19c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5v3.5h-5.3V19c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5v3.5h-5.3V19c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5v3.5h-5.3V19c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5v3.5H35V19c0-.8-.7-1.5-1.5-1.5S32 18.2 32 19v3.5h-5.3V19c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5v3.5H15c-.8 0-1.5.7-1.5 1.5v57c0 .8.7 1.5 1.5 1.5zm1.5-57h7V29c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5v-3.5h5.3V29c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5v-3.5h5.3V29c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5v-3.5h5.3V29c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5v-3.5h5.3V29c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5v-3.5H65V29c0 .8.7 1.5 1.5 1.5S68 29.8 68 29v-3.5h5.3V29c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5v-3.5h7v54h-67v-54h.2z" />
    <circle cx="25" cy="40.4" r="1.5" />
    <circle cx="33.3" cy="40.4" r="1.5" />
    <circle cx="41.7" cy="40.4" r="1.5" />
    <circle cx="50" cy="40.4" r="1.5" />
    <circle cx="58.3" cy="40.4" r="1.5" />
    <circle cx="66.7" cy="40.4" r="1.5" />
    <circle cx="75" cy="40.4" r="1.5" />
    <circle cx="25" cy="49.7" r="1.5" />
    <circle cx="33.3" cy="49.7" r="1.5" />
    <circle cx="41.7" cy="49.7" r="1.5" />
    <circle cx="50" cy="49.7" r="1.5" />
    <circle cx="58.3" cy="49.7" r="1.5" />
    <circle cx="66.7" cy="49.7" r="1.5" />
    <circle cx="75" cy="49.7" r="1.5" />
    <circle cx="25" cy="59" r="1.5" />
    <circle cx="33.3" cy="59" r="1.5" />
    <circle cx="41.7" cy="59" r="1.5" />
    <circle cx="50" cy="59" r="1.5" />
    <circle cx="58.3" cy="59" r="1.5" />
    <circle cx="66.7" cy="59" r="1.5" />
    <circle cx="75" cy="59" r="1.5" />
    <circle cx="25" cy="68.3" r="1.5" />
    <circle cx="33.3" cy="68.3" r="1.5" />
    <circle cx="41.7" cy="68.3" r="1.5" />
    <circle cx="50" cy="68.3" r="1.5" />
    <circle cx="58.3" cy="68.3" r="1.5" />
    <circle cx="66.7" cy="68.3" r="1.5" />
    <circle cx="75" cy="68.3" r="1.5" />
    <path
      d="M41.7 43.7c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm0 9c-1.7 0-3-1.4-3-3s1.4-3 3-3 3 1.4 3 3-1.4 3-3 3z"
      fill={color}
    />
  </svg>
);
