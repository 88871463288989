import React from 'react';

export default ({ color = 'rgb(154,195, 97)' }) => (
  <svg viewBox="0 0 100 100">
    <path
      d="M43.194 64.201l-4.629 4.629-1.816-1.816a1.5 1.5 0 0 0-2.122 2.121l2.877 2.877c.293.293.677.439 1.061.439s.768-.146 1.061-.439l.001-.002 5.688-5.688a1.5 1.5 0 1 0-2.121-2.121z"
      fill={color}
    />
    <path
      d="M45.315 52.211a1.5 1.5 0 0 0-2.121 0l-4.629 4.629-1.816-1.814a1.5 1.5 0 0 0-2.122 2.121l2.877 2.875c.293.293.677.439 1.061.439s.768-.146 1.061-.439h.001l5.688-5.689a1.5 1.5 0 0 0 0-2.122z"
      fill={color}
    />
    <path
      d="M45.315 40.221a1.5 1.5 0 0 0-2.121 0l-4.629 4.629-1.816-1.816a1.5 1.5 0 1 0-2.121 2.121l2.877 2.876a1.496 1.496 0 0 0 2.122 0l5.689-5.69a1.499 1.499 0 0 0-.001-2.12z"
      fill={color}
    />
    <path d="M59.886 60.174a1.5 1.5 0 0 0-1.5 1.5v13.682H30.125V35.071h5.502v.952a1.5 1.5 0 0 0 1.5 1.5h14.657a1.5 1.5 0 0 0 1.5-1.5v-.952h5.102v5.11a1.5 1.5 0 1 0 3 0v-6.61a1.5 1.5 0 0 0-1.5-1.5h-6.602v-3.112a1.5 1.5 0 0 0-1.5-1.5h-1.233c-.157-3.231-2.827-5.814-6.096-5.814-3.27 0-5.94 2.583-6.097 5.814h-1.231a1.5 1.5 0 0 0-1.5 1.5v3.112h-7.002a1.5 1.5 0 0 0-1.5 1.5v43.284a1.5 1.5 0 0 0 1.5 1.5h31.261a1.5 1.5 0 0 0 1.5-1.5V61.674a1.5 1.5 0 0 0-1.5-1.5zm-9.602-25.651H38.627v-4.064h11.657v4.064zm-5.829-9.878c1.614 0 2.929 1.239 3.081 2.814h-6.163a3.105 3.105 0 0 1 3.082-2.814z" />
    <path d="M47.997 57.295l-1.062 4.623a1.5 1.5 0 0 0 1.797 1.796l4.624-1.061a1.498 1.498 0 1 0 .725-2.522L50.52 56.57a1.498 1.498 0 0 0-2.523.725z" />
    <path d="M71.691 38.986c-.762-.762-1.776-1.182-2.854-1.182s-2.091.419-2.854 1.182l-14.091 14.09c-.073.072-.137.15-.161.188a1.5 1.5 0 0 0 .078 2.037l3.569 3.568c.303.303.689.461 1.075.461.327 0 .653-.113.926-.354.058-.043.17-.139.221-.189l14.092-14.092c.763-.763 1.183-1.777 1.183-2.855s-.421-2.092-1.184-2.854z" />
  </svg>
);
