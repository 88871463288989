import React from 'react';
import styled from 'styled-components';

const ImageBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${props => props.imageRight && 'flex-direction: row-reverse;'};

  align-items: center;
  justify-content: center;
  margin: 30px auto;

  & > :not(last-child) {
    margin-left: 30px;
  }

  & li {
    padding: 4px;
  }

  & ul {
    list-style: none;
  }

  & li::before {
    content: '•';
    color: rgb(103, 79, 145);
    font-size: larger;
    width: 20px;
    margin-left: -20px;
    display: inline-block;
  }
`;

const ImageText = styled.div`
  max-width: 500px;
  min-width: 300px;
  flex: 5 5 300px;
`;
const ImageImage = styled.div`
  height: 100%;
  flex: 1 1 250px;
  max-width: ${props => (props.small ? 100 : 300)}px;
`;

export default ({ children, Illustration, imageLeft, small }) => (
  <ImageBox imageRight={!imageLeft}>
    <ImageImage small={small}>
      <Illustration />
    </ImageImage>
    <ImageText>{children}</ImageText>
  </ImageBox>
);
