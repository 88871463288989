import React from 'react';

export default ({ color = 'rgb(154,195, 97)' }) => (
  <svg viewBox="0 0 64 64">
    <path d="M38 8a6 6 0 1 0-6 6 6.006 6.006 0 0 0 6-6zM28 8a4 4 0 1 1 4 4 4 4 0 0 1-4-4z" />
    <path d="M23 20v6a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-6a5.006 5.006 0 0 0-5-5h-.236l-.211.105a7.972 7.972 0 0 1-7.106 0L28.236 15H28a5.006 5.006 0 0 0-5 5zm2 0a3 3 0 0 1 2.787-2.992 10.008 10.008 0 0 0 8.426 0A3 3 0 0 1 39 20v6a1 1 0 0 1-1 1H26a1 1 0 0 1-1-1z" />
    <path d="M53 35a6 6 0 1 0 6 6 6.006 6.006 0 0 0-6-6zm0 10a4 4 0 1 1 4-4 4 4 0 0 1-4 4z" />
    <path d="M57 48h-.236l-.211.1a7.972 7.972 0 0 1-7.106 0l-.211-.1H49a5.006 5.006 0 0 0-5 5v6a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-6a5.006 5.006 0 0 0-5-5zm3 11a1 1 0 0 1-1 1H47a1 1 0 0 1-1-1v-6a3 3 0 0 1 2.787-2.992 10.008 10.008 0 0 0 8.426 0A3 3 0 0 1 60 53z" />
    <path d="M11 35a6 6 0 1 0 6 6 6.006 6.006 0 0 0-6-6zm0 10a4 4 0 1 1 4-4 4 4 0 0 1-4 4z" />
    <path d="M5 62h12a3 3 0 0 0 3-3v-6a5.006 5.006 0 0 0-5-5h-.236l-.211.1a7.972 7.972 0 0 1-7.106 0l-.211-.1H7a5.006 5.006 0 0 0-5 5v6a3 3 0 0 0 3 3zm-1-9a3 3 0 0 1 2.787-2.992 10.008 10.008 0 0 0 8.426 0A3 3 0 0 1 18 53v6a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1z" />
    <path
      d="M41.92 52.781a1 1 0 0 0-1.312-.528 22.182 22.182 0 0 1-14.263.982l.936-.275a1 1 0 1 0-.562-1.92l-4 1.173c-.015 0-.026.017-.04.022a.988.988 0 0 0-.258.143.882.882 0 0 0-.1.063.97.97 0 0 0-.245.34 1 1 0 0 0-.076.413 3.074 3.074 0 0 0 .1.4c.006.014 0 .03.011.044l2 3.827a1 1 0 0 0 1.774-.926l-.814-1.556a24.07 24.07 0 0 0 16.319-.888 1 1 0 0 0 .53-1.314z"
      fill={color}
    />
    <path
      d="M58.707 28.293a1 1 0 0 0-1.414 0l-1.415 1.415a23.964 23.964 0 0 0-14.486-19.8 1 1 0 0 0-.784 1.84 21.967 21.967 0 0 1 13.227 17.673l-1.128-1.128a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.416 0l3-3a1 1 0 0 0-.002-1.414z"
      fill={color}
    />
    <path
      d="M20.567 15.9A.984.984 0 0 0 21 16a1 1 0 0 0 .9-.567l2-4.173c0-.01 0-.021.008-.031a.956.956 0 0 0 .054-.212 1.045 1.045 0 0 0 .038-.165 1 1 0 0 0-.029-.193.927.927 0 0 0-.026-.173c0-.009-.011-.015-.015-.025s0-.017 0-.025a.957.957 0 0 0-.094-.138 1.007 1.007 0 0 0-.114-.165.939.939 0 0 0-.195-.133.885.885 0 0 0-.112-.081l-4-1.827a1 1 0 0 0-.83 1.818l2.071.947A23.963 23.963 0 0 0 8 32a1 1 0 0 0 2 0 21.966 21.966 0 0 1 10.823-18.944l-.723 1.511a1 1 0 0 0 .467 1.333z"
      fill={color}
    />
  </svg>
);
