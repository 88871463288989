import React from 'react';
import styled from 'styled-components';

const Quotation = styled.p`
  & {
    quotes: '\u201C' '\u201D' '\u2018' '\u2019';
  }

  &::before {
    content: open-quote;
    display: inline;
    color: #ccc;
    line-height: 1em;
    font-size: 2em;
  }
  &::after {
    content: close-quote;
    display: inline;
    height: 0;
    line-height: 0;
    position: relative;
    top: 0.7em;
    color: #ccc;
    font-size: 2em;
  }
`;

const Footer = styled.footer`
  font-style: italic;
  text-align: right;
`;

export default ({ author, children }) => (
  <blockquote>
    <Quotation>{children}</Quotation>
    <Footer>{author}</Footer>
  </blockquote>
);
