import React from 'react';
export default ({ color = 'rgb(154,195, 97)' }) => (
  <svg viewBox="0 0 64 64">
    <path d="M28.36 37.508l.913 2.024v-2.499c-.023-.008-.039-.008-.062-.015-10.648-2.324-9.68-15.682-9.68-15.682 10.067-.387 15.682-9.874 15.682-9.874 1.742 6.002 7.744 8.325 7.744 8.325 3.678 4.453 0 6.195 0 6.195s-1.967 8.464-8.131 10.749v2.795l.91-2.018 1.495.28c4.318.806 7.77 1.966 10.336 3.44L46.132 14.73c-.41-7.48-6.59-13.335-14.086-13.335-7.49 0-13.677 5.854-14.08 13.335l-1.442 26.5c2.567-1.475 6.02-2.636 10.341-3.443l1.495-.279z" />
    <path d="M25.44 53.916l.64.087c.048-.804.038-1.995.009-2.798-.184-.281-.28-.61-.28-.959-.05-1.453-.388-2.673-.911-3.205-.213-.223-.446-.31-.794-.31-.446.01-.659.126-.843.31-.62.649-.871 2.15-.9 3.234a2 2 0 0 1-.271.9c-.03.804-.049 2.015 0 2.828l.639-.087a2 2 0 0 1 1.355.29 2.01 2.01 0 0 1 1.356-.29z" />
    <path d="M56.455 58.81l.881 3.795h-7.862c-.698-1.568-1.472-3.011-2.334-4.25a80.991 80.991 0 0 0-1.414 4.25H18.361a52.06 52.06 0 0 0-.455-1.462c-.31-.968-.64-1.888-.959-2.789-.862 1.24-1.636 2.683-2.324 4.251h-7.96c1.066-4.444 2.17-8.211 3.05-10.312 1.163-2.799 2.886-5.249 5.075-7.166.833-.89 1.917-1.646 3.118-2.285 1.307-.707 2.76-1.278 4.231-1.743V43.2c-.62.252-1.18.63-1.665 1.133-1.646 1.704-1.966 4.406-2.014 5.907 0 .31.077.61.223.871-.01.446-.03 1.036-.03 1.637 0 .745.03 1.51.097 2.082.175 1.326 1.172 2.362 2.382 2.576.136.096.29.174.446.232l.794.261c.213.068.426.097.639.097.378 0 .746-.106 1.075-.31.33.204.697.31 1.075.31.213 0 .426-.029.639-.097l.794-.261c.164-.058.32-.136.465-.232a3.018 3.018 0 0 0 2.362-2.576c.136-1.036.107-2.75.068-3.709.145-.28.223-.6.213-.93-.029-1.452-.329-4.134-2.004-5.858A4.808 4.808 0 0 0 26.03 43.2v-3.118c.407-.087.804-.174 1.182-.242l3.127 6.943c.668 1.462 2.74 1.462 3.409 0l3.128-6.943a48.86 48.86 0 0 1 2.769.61v4.503a4.046 4.046 0 0 0-2.092 3.534 4.04 4.04 0 0 0 4.038 4.028 4.04 4.04 0 0 0 4.038-4.028c0-1.5-.832-2.837-2.091-3.534V41.64c2.256.852 4.357 1.995 5.761 3.486 2.189 1.917 3.912 4.367 5.074 7.166.62 1.472 1.346 3.757 2.082 6.517z" />
    <path
      d="M23.592 40.668v3.641a3.37 3.37 0 0 0-2.072 1.027c-1.385 1.433-1.578 3.922-1.607 4.9 0 .164.106.31.242.368h.003c-.024.516-.117 2.841.034 4.043.09.712.654 1.269 1.355 1.351l.326.06c.013.088.071.164.159.193l.794.26a.594.594 0 1 0 .104-1.153l-.828.114a.228.228 0 0 0-.15.095l-.331-.06a1.064 1.064 0 0 1-.936-.922c-.148-1.174-.049-3.566-.03-3.99l.003-.001a.401.401 0 0 0 .252-.349c.02-.871.194-3.05 1.317-4.222.494-.503 1.094-.736 1.86-.745h.019c.745 0 1.346.251 1.83.745 1.133 1.162 1.297 3.35 1.326 4.222 0 .155.097.3.252.359h.004c.02.45.117 2.815-.03 3.98a1.07 1.07 0 0 1-.952.925l-.316.058a.228.228 0 0 0-.15-.095l-.828-.114a.594.594 0 1 0 .104 1.153l.795-.26a.235.235 0 0 0 .158-.194l.31-.057a1.56 1.56 0 0 0 1.37-1.353c.153-1.206.058-3.544.035-4.048a.361.361 0 0 0 .236-.363c-.02-.978-.213-3.467-1.598-4.9a3.358 3.358 0 0 0-2.072-1.017v-3.902c-.33.077-.658.155-.988.251z"
      fill={color}
    />
    <path
      d="M42.085 45.95v-4.813c-.164-.058-.823-.251-.988-.3v5.113a2.587 2.587 0 0 0-2.091 2.537 2.583 2.583 0 0 0 2.585 2.576 2.583 2.583 0 0 0 2.586-2.576c0-1.259-.9-2.304-2.092-2.537zm-.494 4.125a1.589 1.589 0 0 1-1.588-1.588c0-.881.707-1.598 1.588-1.598.881 0 1.588.717 1.588 1.598 0 .872-.707 1.588-1.588 1.588z"
      fill={color}
    />
    <circle cx="41.594" cy="48.478" r=".687" fill={color} />
  </svg>
);
