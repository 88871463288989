import React from 'react';

export default ({ color = 'rgb(154,195, 97)' }) => (
  <svg viewBox="0 0 90 90">
    <path d="M59.609 25.926h-6.814l-2.358-2.675h9.173v2.675zm15.235 5.119H59.36v2.675h15.483v-2.675zm0 7.794H59.36v2.675h15.483v-2.675zm0 7.797H59.36v2.675h15.483v-2.675zM30.869 34.207H15.133v2.675h15.735v-2.675zm15.233 7.794H15.133v2.675h30.968v-2.675zm0 7.793H15.133v2.675h30.968v-2.675zm-3.798 7.797h-27.17v2.675H38.48l3.824-2.675zM44.233 23.9v10.175h9.572v-.25L44.233 23.9zm9.568 25.825V36.882H41.434V23.375H7.55v54.392h38.347l-.005 2.675H4.875V20.7h40.061l11.656 12.046V47.45l-2.791 2.275zM33.125 18v-8h40.694l11.306 11.616V70H67.099l-3.875-3h18.901V26h-12V13h-34v5h-3zm39.991-5.229v10.174h9.571v-.25l-9.571-9.924z" />
    <path
      d="M54.665 52.376l-12.361 8.466 12.361 8.462v-5.658h9.725v-6.094h-9.725v-5.176m3.632 14.582v5.657h-9.725v6.094h9.725v5.179l12.365-8.464-12.365-8.466"
      fill={color}
    />
  </svg>
);
