import React from 'react';

export default ({ color = 'rgb(154,195, 97)' }) => (
  <svg viewBox="0 0 100 100">
    <path d="M15.5 71.2h2v5.7c0 .6.4 1.2.9 1.4.2.1.4.1.6.1.4 0 .8-.2 1.1-.4l6.7-6.7h41.8c2 0 3.6-1.6 3.6-3.6v-7.2h12.2c.7 0 1.4-.2 2-.6 1-.7 1.6-1.8 1.6-3V25.3c0-2-1.6-3.6-3.6-3.6h-53c-.7 0-1.4.2-2 .6-1 .7-1.6 1.8-1.6 3v7.2H15.5c-2 0-3.6 1.6-3.6 3.6v31.5c0 2 1.6 3.6 3.6 3.6zm15.3-45.9c0-.2.1-.4.3-.5.1-.1.2-.1.4-.1h53.1c.4 0 .6.3.6.6v31.5c0 .2-.1.4-.3.5-.1 0-.2.1-.4.1H72.2V36.1c0-2-1.6-3.6-3.6-3.6H30.8v-7.2zm0 10.1h37.8c.4 0 .6.3.6.6v21.3H31.4c-.4 0-.6-.3-.6-.6V35.4zm-15.9.7c0-.4.3-.6.6-.6h12.2v21.3c0 2 1.6 3.6 3.6 3.6h37.8v7.2c0 .4-.3.6-.6.6H26.3c-.5 0-1 .2-1.3.6l-4.5 4.5v-3.1c0-1.1-.9-1.9-1.9-1.9h-3.1c-.4 0-.6-.3-.6-.6V36.1z" />
    <path
      d="M50 51.9c3 0 5.4-2.4 5.4-5.4S53 41 50 41s-5.4 2.4-5.4 5.4 2.4 5.5 5.4 5.5zm0-7.9c1.3 0 2.4 1.1 2.4 2.4s-1.1 2.4-2.4 2.4-2.4-1.1-2.4-2.4S48.7 44 50 44z"
      fill={color}
    />
    <path
      d="M61.8 51.9c3 0 5.4-2.4 5.4-5.4S64.8 41 61.8 41s-5.4 2.4-5.4 5.4 2.4 5.5 5.4 5.5zm0-7.9c1.3 0 2.4 1.1 2.4 2.4s-1.1 2.4-2.4 2.4-2.4-1.1-2.4-2.4 1.1-2.4 2.4-2.4z"
      fill={color}
    />
    <path
      d="M38.2 51.9c3 0 5.4-2.4 5.4-5.4S41.2 41 38.2 41s-5.4 2.4-5.4 5.4 2.4 5.5 5.4 5.5zm0-7.9c1.3 0 2.4 1.1 2.4 2.4s-1.1 2.4-2.4 2.4-2.4-1.1-2.4-2.4 1.1-2.4 2.4-2.4z"
      fill={color}
    />
  </svg>
);
