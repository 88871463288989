import React from 'react';

export default ({ color = 'rgb(154,195, 97)' }) => (
  <svg viewBox="0 0 100 100">
    <switch>
      <g>
        <path d="M71.5 86.2h-43c-5.5 0-10-4.5-10-10v-43c0-5.5 4.5-10 10-10h43c5.5 0 10 4.5 10 10v43c0 5.5-4.5 10-10 10zm-43-60c-3.9 0-7 3.1-7 7v43c0 3.9 3.1 7 7 7h43c3.9 0 7-3.1 7-7v-43c0-3.9-3.1-7-7-7h-43z" />
        <path d="M38 39.3c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5 5.5 2.5 5.5 5.5-2.5 5.5-5.5 5.5zm0-8c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5z" />
        <path d="M62 39.3c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5 5.5 2.5 5.5 5.5-2.5 5.5-5.5 5.5zm0-8c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5z" />
        <path d="M80 44.1H20c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h60c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5z" />
        <path
          d="M63.2 65.2h-25c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h25c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5z"
          fill={color}
        />
        <path
          d="M36.8 67.4c-.3 0-.5-.1-.8-.2-.7-.4-.9-1.4-.5-2.1l.9-1.4-.9-1.4c-.4-.7-.2-1.6.5-2.1.7-.4 1.6-.2 2.1.5l1.4 2.2c.3.5.3 1.1 0 1.6l-1.4 2.2c-.3.5-.8.7-1.3.7z"
          fill={color}
        />
        <path
          d="M48.8 76.4L46.1 75s4.5-8.8 5.8-11.3l-5.8-11.3c-.4-.7-.1-1.6.6-2 .7-.4 1.6-.1 2 .6L55 63c.1.2.2.4.2.7-.1.4-.1.4-6.4 12.7z"
          fill={color}
        />
        <g>
          <path d="M38 32.8c-.8 0-1.5-.7-1.5-1.5v-16c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v16c0 .8-.7 1.5-1.5 1.5z" />
        </g>
        <g>
          <path d="M62 32.8c-.8 0-1.5-.7-1.5-1.5v-16c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v16c0 .8-.7 1.5-1.5 1.5z" />
        </g>
      </g>
    </switch>
  </svg>
);
