import React from 'react';
export default ({ color = 'rgb(154,195, 97)' }) => (
  <svg viewBox="0 0 64 64">
    <path d="M31.988 39.114c8.241 0 14.923-14.3 14.923-22.542S40.229 1.649 31.988 1.649 17.066 8.33 17.066 16.572s6.68 22.542 14.922 22.542z" />
    <path d="M60.83 62.35H3.17c-1.582 0-2.751-1.443-2.462-2.997C2.11 51.829 4.774 46.125 9.171 44.77c0 0 6.56-2.291 9.448-3.296.89-.31 1.869.109 2.26.966l8.83 16.15c.938 1.636 3.297 1.638 4.238.005l9.15-16.155a1.825 1.825 0 0 1 2.26-.966c2.887 1.005 9.448 3.296 9.448 3.296 4.396 1.355 7.06 7.055 8.485 14.576.295 1.556-.875 3.005-2.46 3.005z" />
    <path
      d="M36.09 43.236l-1.046 4.302c-.092.39-.462.657-.888.665l-4.661-.033c-.426-.017-.786-.291-.879-.673l-.971-4.328c-.111-.523.342-1.013.934-.996l6.604.041c.592 0 1.036.499.906 1.022z"
      fill={color}
    />
    <path
      d="M34.875 49.294h-6.01l-.645 3.476 2.88 5.08c.15.28.45.43.77.44.32-.01.62-.16.77-.44l2.88-5.08-.645-3.476z"
      fill={color}
    />
  </svg>
);
